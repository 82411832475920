@import url("https://fonts.googleapis.com/css2?family=Padauk:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
}
*:focus {
  outline: 0;
}
*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: 'Padauk', sans-serif;
  overflow-x: hidden;
}
.logo {
  display: block;
}
@media (min-width: 480px) {
  .logo {
    position: fixed;
    left: 120px;
    top: 80px;
    width: 292px;
    height: 211px;
  }
}
@media (max-width: 479px) {
  .logo {
    position: relative;
    top: 4%;
    left: auto;
    margin: 0 auto;
    width: 60%;
    height: auto;
  }
  .logo.outofsight {
    opacity: 0;
    top: 0%;
  }
}
img.note {
  position: absolute;
  opacity: 0;
  width: 32px;
  height: 32px;
  z-index: -2;
}
.madTalks {
  font-size: 22px;
  font-weight: 700;
  position: absolute;
  opacity: 0;
  z-index: -2;
}
.album-list {
  position: absolute;
  width: 3600px;
  height: 100%;
  z-index: 1;
}
.container-test {
  position: absolute;
  bottom: 9%;
  width: 4200px;
}
@media (max-width: 479px) {
  .container-test {
    position: relative;
    width: 100%;
    top: 0;
  }
}
.album {
  position: relative;
  display: inline-block;
  width: 600px;
  height: 400px;
  -webkit-transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.album.play {
  z-index: 99;
}
@media (min-width: 480px) {
  .album {
    margin-right: -120px;
  }
  .album.play {
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(-10%);
    margin-right: 0px;
    margin-left: 40px;
  }
}
@media (max-width: 479px) {
  .album {
    display: block;
    width: 100%;
  }
}
.album a.CTAspotify {
  text-decoration: none;
  color: #fff;
  background: #000;
  font-size: 20px;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  padding: 4px 24px;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.5, 1, 0.89, 1);
  -o-transition: 0.3s cubic-bezier(0.5, 1, 0.89, 1);
  transition: 0.3s cubic-bezier(0.5, 1, 0.89, 1);
  z-index: 5;
}
@media (min-width: 480px) {
  .album a.CTAspotify {
    position: absolute;
    display: inline-block;
    bottom: -44px;
    right: 19.4%;
  }
}
@media (max-width: 479px) {
  .album a.CTAspotify {
    display: inline-block;
    position: relative;
    left: 0;
    top: 87%;
    margin: 0 auto;
  }
}
.album.play a.CTAspotify {
  opacity: 1;
}
@media (max-width: 479px) {
  .album.play a.CTAspotify {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
  }
}
.album .albumName {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
@media (min-width: 480px) {
  .album .albumName {
    position: absolute;
    display: inline-block;
    bottom: -41px;
    left: 19.4%;
  }
}
@media (max-width: 479px) {
  .album .albumName {
    position: relative;
    display: block;
  }
}
.album:hover .albumName,
.album.play .albumName {
  opacity: 1;
}
.album .cover-shadow {
  position: absolute;
  display: block;
  width: 100%;
  z-index: 0;
}
.album .comboCounter {
  font-size: 28px;
  font-weight: 700;
  font-variant: small-caps;
}
@media (min-width: 480px) {
  .album .comboCounter {
    position: absolute;
    left: 19.4%;
    bottom: -48px;
  }
}
@media (max-width: 479px) {
  .album .comboCounter {
    position: relative;
    display: block;
  }
}
.album .comboCounter.display,
.album .cover {
  Cursor: Pointer;
  webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  left: 19.4%;
  top: 4.4%;
  width: 61.3%;
  z-index: 3;
  cursor: pointer !important;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.album .comboCounter.display:hover,
.album .cover:hover {
  -webkit-filter: brightness(var(--value, 5));
  filter: brightness(var(--value, 5));
  --value: 1.05;
}
.album .nextButton {
  display: inline-block;
  z-index: 2;
  opacity: 0.8;
}
.album .nextButton.nonext {
  display: none;
}
@media (min-width: 480px) {
  .album .nextButton {
    position: absolute;
    right: 22%;
    top: 47%;
  }
}
@media (max-width: 479px) {
  .album .nextButton {
    position: absolute;
    left: 41%;
    top: 13%;
  }
}
.album .nextButton .next-triangle {
  -webkit-animation: fadeInOut 1.5s infinite cubic-bezier(0.37, 0, 0.63, 1);
  animation: fadeInOut 1.5s infinite cubic-bezier(0.37, 0, 0.63, 1);
}
.album .nextButton .next-triangle:first-of-type {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
@media (min-width: 480px) {
  .album .nextButton .next-triangle {
    width: 30%;
    display: inline-block;
    margin-right: -10%;
  }
}
@media (max-width: 479px) {
  .album .nextButton .next-triangle {
    width: 23%;
    display: inline-block;
    margin-right: -10%;
  }
}
.album .vinyl {
  webkit-tap-highlight-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.album .vinyl::after {
  display: block;
  position: absolute;
  content: "NEXT";
  top: 170px;
  right: 40px;
  color: rgba(255,255,255,0.8);
  font-size: 18px;
  font-weight: 700;
  -webkit-animation: pulse 6s ease-out alternate infinite;
  animation: pulse 6s ease-out alternate infinite;
}
.album .vinyl:hover {
  -webkit-filter: brightness(var(--value, 5));
  filter: brightness(var(--value, 5));
  --value: 1.8;
}
.album .vinyl .vinyl-img {
  position: absolute;
  top: 5%;
  left: 19.8%;
  width: 60%;
  -webkit-animation: vinyl-rotate 10s linear infinite;
  animation: vinyl-rotate 10s linear infinite;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.album.play .vinyl-img {
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.album.play .vinyl {
  -webkit-transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  -o-transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  left: 33%;
}
@media (max-width: 479px) {
  .album.play .vinyl {
    left: 0;
    top: -32%;
  }
}
.album .vinyl-shadow {
  position: absolute;
  right: 5%;
  opacity: 0;
  bottom: -2.5%;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}
@media (max-width: 479px) {
  .album .vinyl-shadow {
    display: none;
  }
}
.album.play .vinyl-shadow {
  opacity: 1;
}
@media (max-width: 479px) {
  .album-list {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 40%;
  }
  .album {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 100px;
  }
  .album .vinyl {
    top: 0;
  }
  .album .cover-shadow {
    position: relative;
  }
  .album .vinyl-shadow {
    display: none;
  }
  .album .albumName {
    opacity: 1;
  }
  .album a.CTAspotify {
    position: relative;
    display: inline-block;
    left: 0;
    margin: 10px auto;
  }
  .album:hover .albumName,
  .album.play .albumName {
    position: relative;
    display: block;
    top: 72%;
    left: 0%;
    margin: 0 auto;
  }
  .album.play .vinyl {
    left: 0;
    top: -32%;
  }
  .album.play a.CTAspotify {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
  }
}
.signup-form {
  display: inline-block;
  position: absolute;
  width: 594px;
  height: 403px;
  text-align: center;
  margin-top: 100px;
  margin-left: 150px;
  margin-right: 50px;
}
@media (max-width: 479px) {
  .signup-form {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 24px;
  }
}
.signup-form * {
  max-width: 100%;
}
.signup-form h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 100%;
}
@media (max-width: 479px) {
  .signup-form h2 {
    font-size: 32px;
  }
}
.signup-form p {
  margin: 24px auto;
  font-size: 24px;
  line-height: 120%;
  width: 450px;
}
@media (max-width: 479px) {
  .signup-form p {
    font-size: 20px;
  }
}
.signup-form .mc-field-group {
  position: relative;
  display: inline-block;
  width: 380px;
}
.signup-form .button {
  position: absolute;
  display: inline-block;
  text-align: center;
  color: #fff;
  background: #f20001;
  font-size: 20px;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  padding: 0px 24px;
  height: 36px;
  top: 8px;
  right: 8px;
}
.signup-form .button:hover {
  background: #bf0001;
}
.signup-form .email {
  font-size: 18px;
  line-height: 0%;
  height: 52px;
  border: 1px solid #cbcbcb;
  color: #464646;
  border-radius: 50px;
  width: 100%;
  padding-left: 16px;
  font-weight: 400;
}
.signup-form .email::-webkit-input-placeholder {
  color: #a7b1ba;
}
.signup-form .email:-ms-input-placeholder {
  color: #a7b1ba;
}
.signup-form .email::-ms-input-placeholder {
  color: #a7b1ba;
}
.signup-form .email::placeholder {
  color: #a7b1ba;
}
.signup-form .email:hover,
.signup-form .email:focus {
  border-color: #66637e;
}
.signup-form .contribute {
  color: #666;
  font-size: 18px;
  display: inline-block;
}
.signup-form .contribute a {
  color: #f20001;
}
.album.play .vinyl img {
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000;
  background-color: rgba(0,0,0,0.7);
}
.modal-content {
  position: relative;
  font-size: 20px;
  background-color: #fefefe;
  margin: 15% auto;
  padding: 40px;
  border: 1px solid #888;
  width: 40%;
  border-radius: 20px;
  text-align: center;
}
@media (max-width: 479px) {
  .modal-content {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    border-radius: 20px 20px 0 0;
    padding: 40px 24px;
    paragraph-height: 16px;
  }
}
.modal-content p {
  margin-top: 16px;
}
.modal-content a {
  color: #f20001;
}
.modal-content h3 {
  font-size: 40px;
}
.close {
  position: absolute;
  right: 12px;
  top: -3px;
  color: #aaa;
  font-size: 38px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
@-moz-keyframes fadeInOut {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-webkit-keyframes fadeInOut {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-o-keyframes fadeInOut {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-moz-keyframes vinyl-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes vinyl-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes vinyl-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes vinyl-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
